<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label ">
        {{ $t('banks.banks') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('banks.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('banks.deposit') }}</label>
                <select name="" id="deposit" v-model="data.deposit" class="custom-select" :class="validation && validation.deposit ? 'is-invalid' : ''">
                  <option v-for="row in deposits" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.deposit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.deposit[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('banks.withdraw') }}</label>
                <select name="" id="withdraw" v-model="data.withdraw" class="custom-select" :class="validation && validation.withdraw ? 'is-invalid' : ''">
                  <option v-for="row in withdraws" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.withdraw" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.withdraw[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('banks.bank_no') }}</label>
                <input type="text" v-model="data.bank_no" class="form-control" :class="validation && validation.bank_no ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.bank_no" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.bank_no[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('banks.currency') }}</label>
                <div class="input-group">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('banks.currencies_allowed') }}</label>
                <div class="input-group">
                  <multiselect v-model="currencies_allowed_outer"
                               :placeholder="$t('banks.currency')"
                               label="name"
                               track-by="id"
                               :options="currencies"
                               :multiple="true"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.supplier_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.supplier_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('banks.balances') }}</h6>
          </div>
          <div class="bg-white">
            <table class="table table-row-bordered">
              <thead>
              <tr>
                <th>{{ $t('banks.account_no') }}</th>
                <th>{{ $t('banks.iban_no') }}</th>
                <th>{{ $t('banks.balance') }}</th>
                <th>{{ $t('banks.currency') }}</th>
                <th>{{ $t('banks.last_validated_date') }}</th>
                <th></th>
              </tr>

              </thead>
              <tbody>
              <template v-if="items_list.length > 0">
                <tr v-for="(row, index) in items_list" :key="index">

                  <td><input v-model="row.account_no" :disabled="true" type="number" min="0.01" step="0.01" class="form-control"></td>
                  <td><input v-model="row.iban" :disabled="true" type="number" min="0.01" step="0.01" class="form-control"></td>
                  <td><input v-model="row.balance" :disabled="true" type="number" min="0.01" step="0.01" class="form-control"></td>
                  <td>
                    <select name="" id="" :disabled="true" v-model="row.currency_id" class="custom-select">
                      <option v-for="cc in currencies" :value="cc.id" :key="cc.id">{{ cc.name }}</option>
                    </select>
                    <!--                                                <span class="form-text text-muted" style="text-align:end">{{ $t('select_currencies_allowed_outer') }}.</span>-->
                  </td>
                  <td>
                    {{ row.last_validated_date }}
                  </td>
                  <td>
                    <v-icon style="color: #dc3545;" color="danger" small v-if="items_list.length > 1 && !row.id" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
              </template>

              <tr v-else>
                <td colspan="6">{{ $t('no_balances') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('banks.notes') }}</label>
                  <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.notes[0] }}
                                    </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-banks",
  data() {
    return {
      mainRoute: 'finances/banks',
      mainRouteDependency: 'base/dependency',

      data: {
        name: null,
        notes: null,
        balance: null,
        deposit: null,
        bank_no: null,
        withdraw: null,
        is_active: true,
        currency_id: null,
        currencies_allowed: [],
      },
      idEditing: this.$route.params.id,
      isEditing: false,
      currencies_allowed_outer: [],
      isDisabled: false,

      deposits: [],
      withdraws: [],
      currencies: [],
      items_list: [],
      items_list_form: {id: null, account_no: null, balance: null, currency_id: null, last_validated_date: null},

      validation: null,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {


    currencies_allowed_outer: function (val) {
      this.data.currencies_allowed = [];
      if (val) {
        this.data.currencies_allowed = val.map((row) => row.id);
      }
    }
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      // this.data.is_active = this.data.is_active ? 1 : 0;
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        items_list: this.items_list,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'banks.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      this.data.is_active = this.data.is_active ? 1 : 0;
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        items_list: this.items_list,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'banks.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.notes = response.data.data.notes;
        // this.data.balance = response.data.data.balance;
        this.data.deposit = response.data.data.deposit;
        this.data.withdraw = response.data.data.withdraw;
        this.data.is_active = response.data.data.is_active;
        this.data.currency_id = response.data.data.currency_id;
        this.data.bank_no = response.data.data.bank_no;
        this.data.currencies_allowed = response.data.data.currencies_allowed;
        this.currencies_allowed_outer = this.currencies.filter((row) => response.data.data.currencies_allowed.includes(row.id));

        // if (!response.data.data.items_list || (response.data.data.items_list && response.data.data.items_list.length <= 0)) {
        //     this.addItemRowToList();
        // }else {
        this.items_list = response.data.data.items_list ? response.data.data.items_list : [];
        // }
      });
    },

    getDeposit() {
      ApiService.get(this.mainRouteDependency + "/deposit_list").then((response) => {
        this.deposits = response.data.data;
      });
    },

    getWithdraw() {
      ApiService.get(this.mainRouteDependency + "/withdraw_list").then((response) => {
        this.withdraws = response.data.data;
      });
    },

    async getCurrencies() {
      await ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    loadOptions() {
    },

    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, account_no: null, balance: null, currency_id: null, last_validated_date: null};
    },
    removeItemRowFromList(index) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.banks_management"), route: '/finances/banks'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    // this.addItemRowToList();

    let promise = this.getCurrencies();
    if (this.idEditing) {
      Promise.all([promise]).then(() => {
        this.getData();
      })
    } else {
      this.defaultDataForUser();
    }

    this.getDeposit();
    this.getWithdraw();


  },
};
</script>